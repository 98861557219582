/* Adyen checkout
GENERAL */
.adyen-parent {
  button.adyen-checkout__button {
    border-radius: 0;
    background: #341c4e;
    color: white;
    font-weight: bold;
    outline: none;
    border: none;
    transition: all 0.2s ease;
    text-transform: uppercase;
    font-size: 16px;
    padding: 1rem;
    align-self: flex-end;
    cursor: pointer;

    &:hover,
    &:focus {
      background: #2d4353;
      box-shadow: none !important;
    }

    span {
      font-size: 16px;
      letter-spacing: 1px;
    }
  }
}

/* Adyen checkout
IDEAL */
#adyen-ideal {
  .adyen-checkout__dropdown__button {
    padding: 0 !important;
    height: 3.25rem;
    border: 0 !important;
    box-shadow: none !important;

    &:focus {
      box-shadow: none !important;
    }
  }

  .adyen-checkout__dropdown__button--active {
    border: 1px solid #141414 !important;
  }

  .adyen-checkout__field--issuer-list {
    width: 100%;
  }

  .adyen-checkout__input-wrapper {
    height: auto;
  }

  .adyen-checkout__issuer-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
    margin-right: auto;
    width: 100%;
  }

  input {
    width: 100%;
    border: 1px solid #bdbdbd;
    padding: 0 0.75rem;
    margin-bottom: 0;
    height: 100%;
    transition: all 0.2s ease;
    background: transparent;

    &:hover,
    &:focus {
      border: 1px solid #141414;
    }

    :placeholder {
      opacity: 0;
    }
  }

  .adyen-checkout__dropdown__list {
    margin-top: 0 !important;
    border-radius: 0 !important;

    li {
      display: flex;
    }
  }
}

/* Adyen checkout
CREDIT CARD */
#adyen-scheme {
  .adyen-checkout-form-instruction {
    margin-top: 1rem;
    margin-bottom: 0.5rem !important;
    display: block;
  }
  .adyen-checkout__label {
    background: white;
    height: auto;
    margin-bottom: 0.5rem;
    color: #808080;
    position: relative;
    display: none;

    /* .adyen-checkout__label__text {
      display: none;
    } */
  }

  .adyen-checkout-form-instruction {
    font-size: 0.625rem;
    color: #909090;
    margin-bottom: 0.25rem;

    @media (min-width: 768px) {
      font-size: 0.75rem;
    }
  }

  .adyen-checkout__card__cardNumber__input {
    img {
      max-width: 1.5rem;
    }
  }

  .adyen-checkout__input-wrapper {
    img,
    svg {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0.5rem;
    }
  }

  .adyen-checkout__input {
    box-shadow: none !important;

    &:hover {
      border: 1px solid #141414;
    }
  }

  .adyen-checkout__card__exp-cvc {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.5rem;
    margin-bottom: 1rem;
  }

  .adyen-checkout__input--focus {
    border: 1px solid #141414;
  }

  .adyen-checkout__card__exp-cvc {
    grid-template-columns: 1fr 1fr !important;
    width: 100%;
  }

  .adyen-checkout__card__brands {
    margin-top: 0.25rem;
    margin-bottom: 0.75rem;
    display: flex;
    gap: 0.25rem;

    img {
      width: 1.25rem;
      height: auto;
    }
  }

  .adyen-checkout__field--expiryDate,
  .adyen-checkout__field--securityCode {
    grid-column: auto !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
    width: 100% !important;
  }
}
